import { twMerge } from "tailwind-merge";
import { MotionLogo } from "../astro/icons/motion-logo";
import { motion } from "motion/react";
import { useSparkles, Sparkle } from "../sparkles";

const FascinatedText = ({
  text,
  delay,
  rotate,
}: {
  text: string;
  delay?: number;
  rotate: "left" | "right";
}) => {
  const splitText = text.split("");
  const sparkles = useSparkles({ sparklesCount: 8 });

  return (
    <span className="relative inline-block select-none">
      <span className="sr-only">{text}</span>
      {sparkles.filter(Boolean).map((sparkle) => (
        <Sparkle key={sparkle.id} {...sparkle} />
      ))}
      <motion.span
        whileInView="fascinate"
        initial="static"
        variants={{
          fascinate: {
            opacity: 1,
          },
          static: { opacity: 0.3 },
        }}
        transition={{
          duration: 0.3,
          delay: (delay ?? 0) - 0.1,
          ease: "circOut",
          staggerChildren: 0.1,
          delayChildren: (delay ?? 0) - 0.1,
        }}
        className={twMerge(
          "bg-white-opaque border-white-opaque relative mx-3 inline-block rounded-2xl border-4 px-2 py-1 shadow-2xl md:px-5 md:py-2",
          rotate === "left" && "rotate-[-3deg]",
          rotate === "right" && "rotate-[3deg]",
        )}
      >
        {splitText.map((char, index) => (
          <motion.span
            variants={{
              fascinate: { opacity: 1, x: 0, scale: 1 },
              static: { opacity: 0, x: -10, scale: 0.5 },
            }}
            key={char + index}
            className="inline-block"
          >
            {char}
          </motion.span>
        ))}
      </motion.span>
    </span>
  );
};

export const CourseTitle = () => {
  return (
    <h1 className="mx-auto mb-8 items-center text-center text-2xl font-medium leading-snug md:max-w-[1000px] md:text-3xl md:leading-[1] lg:text-4xl">
      <span className="relative z-10 inline">Crafting beautiful</span>
      <FascinatedText rotate="left" text="Ooooh" delay={0.1} />
      <FascinatedText rotate="right" text="Aaaaah" delay={0.5} />

      <span className="relative z-10 inline">
        experiences with{" "}
        <span className="inline-block items-baseline justify-center md:inline-flex">
          <motion.span
            whileInView="fascinate"
            initial="static"
            variants={{
              fascinate: {
                scale: [1, 0.95, 0.95, 1.1, 1.1, 0.95, 0.95, 1, 1, 1],
              },
              static: {
                scale: 1,
              },
            }}
            transition={{
              duration: 2,
              delay: 1.5,
              repeat: 1,
              ease: "easeInOut",
              times: [0, 0.05, 0.1, 0.2, 0.3, 0.45, 0.5, 0.6, 0.7, 0.75],
            }}
            className="relative -top-1 mr-2 inline-grid h-8 w-8 place-items-center lg:-top-3 lg:mr-5 lg:h-10 lg:w-10"
          >
            <MotionLogo className="inline-grid h-8 w-8 self-center shadow-2xl lg:h-10 lg:w-10" />
          </motion.span>
          Motion for React
        </span>
      </span>
      <span className="text-md block font-normal tracking-normal">
        (formerly Framer Motion)
      </span>
    </h1>
  );
};
