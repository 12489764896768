import { useEffect, useState } from "react";
import { motion } from "motion/react";

interface Sparkle {
  id: string;
  x: string;
  y: string;
  color: string;
  delay: number;
  scale: number;
  duration: number;
  lifespan: number;
}

export const Sparkle = ({
  id,
  x,
  y,
  color,
  delay,
  scale,
  duration,
}: Sparkle) => {
  return (
    <motion.svg
      key={id}
      aria-hidden
      className="pointer-events-none absolute z-10 origin-center"
      initial={{ opacity: 0, left: x, top: y }}
      animate={{
        opacity: [0, 1, 0],
        scale: [0, scale, 0],
        rotate: [70, 100, 150],
      }}
      transition={{ duration: duration, delay }}
      width="16"
      height="16"
      viewBox="0 0 122 117"
    >
      <path
        fill={color}
        d="M64.39,2,80.11,38.76,120,42.33a3.2,3.2,0,0,1,1.83,5.59h0L91.64,74.25l8.92,39a3.2,3.2,0,0,1-4.87,3.4L61.44,96.19,27.09,116.73a3.2,3.2,0,0,1-4.76-3.46h0l8.92-39L1.09,47.92A3.2,3.2,0,0,1,3,42.32l39.74-3.56L58.49,2a3.2,3.2,0,0,1,5.9,0Z"
      />
    </motion.svg>
  );
};

const randomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;

const defaultColors = ["#FFD700", "#FFA500"];

type SparklesConfig = {
  colors: string[];
  duration: [number, number];
  scale: [number, number];
  delay: [number, number];
  sparklesCount: number;
};

const generateSparkle = (config: Partial<SparklesConfig> = {}): Sparkle => {
  const colors = config.colors ?? defaultColors;
  const duration = config.duration ?? [1.5, 3.4];
  const scale = config.scale ?? [0.6, 2.5];
  const delay = config.delay ?? [0, 2];

  const sparkleX = `${randomNumber(0, 100)}%`;
  const sparkleY = `${randomNumber(0, 100)}%`;
  const sparkleColor = colors[randomNumber(0, colors.length)];
  const sparkleScale = randomNumber(scale[0], scale[1]);
  const sparkleDelay = randomNumber(delay[0], delay[1]);
  const sparkleDuration = randomNumber(duration[0], duration[1]);
  const sparkleLifespan = sparkleDuration + sparkleDelay;
  const sparkleId = `${sparkleX}-${sparkleY}-${randomNumber(0, 1000000)}`;

  return {
    id: sparkleId,
    x: sparkleX,
    y: sparkleY,
    color: sparkleColor,
    delay: sparkleDelay,
    scale: sparkleScale,
    duration: sparkleDuration,
    lifespan: sparkleLifespan,
  };
};

export const useSparkles = (config: Partial<SparklesConfig> = {}) => {
  const sparklesCount = config.sparklesCount ?? randomNumber(5, 10);

  const [sparkles, setSparkles] = useState<Sparkle[]>([]);

  useEffect(() => {
    const initializeStars = () => {
      setSparkles(
        Array.from({ length: sparklesCount }, () => generateSparkle(config)),
      );
    };

    const updateStars = () => {
      setSparkles((currentSparkles) =>
        currentSparkles.map((sparkle) => {
          if (sparkle.lifespan <= 0) {
            return generateSparkle(config);
          } else {
            return { ...sparkle, lifespan: sparkle.lifespan - 0.1 };
          }
        }),
      );
    };

    initializeStars();
    const interval = setInterval(updateStars, 100);

    return () => clearInterval(interval);
    // We purposefully don't include config in the dependency array
    // because we don't want to re-run the effect when config changes
  }, []);

  return sparkles;
};
