import {
  useScroll,
  motion,
  useMotionValueEvent,
  AnimatePresence,
} from "motion/react";
import type { Variant } from "motion/react";
import { X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export const BuyCourseWithProButton = ({
  external = false,
}: {
  external?: boolean;
}) => {
  const [variant, setVariant] = useState<"hidden" | "visible" | "stfu">(
    "hidden",
  );
  const { scrollY } = useScroll();
  const [stfu, setStfu] = useState(false);

  useMotionValueEvent(scrollY, "change", (y) => {
    if (stfu) return;

    if (y > 100) {
      setVariant("visible");
    } else {
      setVariant("hidden");
    }
  });

  useEffect(() => {
    if (stfu) {
      setVariant("stfu");
    }
  }, [stfu]);

  const reveal = useCallback(() => {
    if (stfu) {
      setStfu(false);
      setVariant("visible");
    }
  }, [stfu]);

  return (
    <motion.div
      variants={{
        hidden: { "--y": "200%" } as Variant,
        visible: { "--y": "0%" } as Variant,
        stfu: { "--y": "90%" } as Variant,
      }}
      onClick={reveal}
      initial="hidden"
      animate={variant}
      className="not-prose border-white-opaque bg-white-opaque group fixed bottom-3 left-1/2 z-20 block w-max max-w-[90%] -translate-x-1/2 translate-y-[--y] rounded-2xl border-2 px-3 py-2 text-white shadow-lg backdrop-blur-2xl transition-colors duration-500 md:rounded-full md:px-6 md:py-4"
    >
      <AnimatePresence>
        {!stfu && (
          <motion.button
            onClick={() => setStfu(true)}
            className="bg-background-contrast border-white-opaque absolute -right-2 -top-2 rounded-full border p-2 md:-right-1 md:-top-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <X className="h-4 w-4" />
          </motion.button>
        )}
      </AnimatePresence>
      {stfu && (
        <motion.span
          className="bg-white-opaque absolute left-1/2 top-2 block h-2 w-8 -translate-x-1/2 rounded-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      )}
      <a
        href={external ? "https://www.frontend.fyi/pro" : "/pro"}
        target={external ? "_blank" : undefined}
        className={twMerge(
          "flex gap-2 transition-opacity duration-300 max-md:flex-col md:items-center md:gap-6",
          stfu && "pointer-events-none opacity-0",
        )}
      >
        <p className="md:text-md pr-6 text-[12px]">
          Course included in{" "}
          {external ? (
            <span className="underline">Frontend.fyi PRO.</span>
          ) : (
            "PRO."
          )}
          <br />
          No subscription, regularly new premium content.
        </p>
        <p className="text-md bg-pro border-white-opaque group-hover:bg-pro-light group-focus-visible:bg-pro-light rounded-full border px-3 py-1 text-center font-bold text-black transition-colors duration-300 md:text-xl">
          Join today for €249
        </p>
      </a>
    </motion.div>
  );
};
