import { twMerge } from "tailwind-merge";
import { motion, useScroll } from "motion/react";
import type { MotionStyle } from "motion/react";
import { useRef, useState } from "react";
import { BuyCourseWithProButton } from "@fefyi/ui/buy-course-with-pro-btn";
import { Button } from "@fefyi/ui/button";
import { Container } from "@fefyi/ui/container";
import { useStoryLine, type StoryLine } from "../utils/use-story-timeline";
import { CourseTitle } from "@fefyi/ui/framer-motion/course-title";

export const PageTitle = () => {
  return (
    <>
      <BuyCourseWithProButton external />
      <div className="fadeout-bottom relative mb-6 grid place-items-center bg-[radial-gradient(#110112,_#7307cb38),_linear-gradient(45deg,_#170c02,_#160a0a78)] pb-60 [--fade-distance:140px] md:mb-12">
        <Container>
          <div>
            <div className="grid min-h-[calc(100svh-160px)] place-items-center pt-80">
              <CourseTitle />
            </div>
            <div className="flex items-center gap-4">
              <img
                src="/me.webp"
                alt="Jeroen Reumkens"
                className="aspect-square w-10 rounded-full border border-white-opaque object-cover shadow-lg"
              />
              <p className="mb-0">
                Taught by Jeroen Reumkens
                <span className="block text-md leading-[1] opacity-60">
                  Founder of{" "}
                  <a href="https://www.frontend.fyi" target="_blank">
                    Frontend.fyi
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="mt-auto grid gap-8 lg:grid-cols-2">
            <div>
              <p className="mb-4 max-w-[70ch] text-xl">
                Making smooth, performant and beautiful animations is hard.{" "}
                <a href="https://motion.dev/">Motion for React</a> helps you
                overcome a lot of these struggles.
              </p>
              <p className="mb-4 max-w-[70ch] text-xl">
                In this course, I'll guide you through everything Motion has to
                offer. Together, we take a look at all of Motion's APIs, and
                craft impressive real-world projects.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

type MotionWrapperProps = {
  children: React.ReactNode;
  style: MotionStyle;
  className?: string;
  sectionSize: string;
};

const TextSection = ({
  children,
  style,
  className,
  sectionSize,
}: MotionWrapperProps) => (
  <motion.section className={twMerge("flex place-items-center", sectionSize)}>
    <motion.div
      style={style}
      className={twMerge(
        "fixed left-1/2 top-1/2 w-[100ch] max-w-[90vw] -translate-x-1/2 -translate-y-1/2",
        className,
      )}
    >
      <div className="[transform:scale(var(--text-scale))]">{children}</div>
    </motion.div>
  </motion.section>
);

const storyline = {
  root: {
    time: [0, 1],
    animations: {
      scale: { time: [0, 0.4, 0.6], value: [1, 0.9, 0.8] },
      circleScale: { time: [0, 0.6, 1], value: [1, 2.4, 1.2] },
      circleOpacity: { time: [0, 0.1, 0.95, 1], value: [0, 1, 1, 0] },
      circle2Scale: { time: [0, 0.6, 1], value: [1, 1, 2.8] },
      circle2Opacity: { time: [0, 0.44, 0.95, 1], value: [0, 1, 1, 0] },
    },
  },
  screen1: {
    time: [0.02, 0.4],
    animations: {
      position: {
        time: [0, 0.001, 1.1, 1.11],
        value: ["relative", "fixed", "fixed", "relative"],
      },
      heading1: { time: [0, 0.2, 0.999, 1], value: [0, 1, 1, 0] },
      heading2: { time: [0.2, 0.4, 0.999, 1], value: [0, 1, 1, 0] },
      heading3: { time: [0.4, 0.6, 0.999, 1], value: [0, 1, 1, 0] },
      unmatched: { time: [0.6, 0.7, 0.999, 1], value: [0, 1, 1, 0] },
      lineScale: { time: [0.6, 0.7, 0.999, 1], value: [0, 1, 1, 0] },
    },
  },
  screen2: {
    time: [0.4, 0.6],
    animations: {
      position: {
        time: [0, 0.001, 1.1, 1.11],
        value: ["relative", "fixed", "fixed", "relative"],
      },
      usingReactOpacity: { time: [0, 0.2, 0.8, 1], value: [0, 1, 1, 0] },
      usingReactScale: { time: [0, 0.2, 1], value: [1, 1, 1.3] },
    },
  },
  screen3: {
    time: [0.6, 1],
    animations: {
      position: {
        time: [0, 0.001, 1.1, 1.11],
        value: ["relative", "fixed", "fixed", "relative"],
      },
      whyOpacity: { time: [0, 0.1, 0.6, 0.8], value: [0, 1, 1, 0] },
      whyX: { time: [0, 1], value: [0, 140] },
      lineX: { time: [0, 1], value: [0, -140] },
      lineOpacity: { time: [0, 0.05, 0.1, 0.6, 0.8], value: [0, 0, 1, 1, 0] },
    },
  },
} satisfies StoryLine;

export const FramerMotionIsUnmatched = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const [currentChapter, animations] = useStoryLine<typeof storyline>(
    scrollYProgress,
    storyline,
  );

  // const scale = useTransform(scrollYProgress, [0.05, 1], [1, 0.8], {
  //   ease: easeInOutQuad,
  // });

  // // section 1
  // const headingPart1Opacity = useTransform(
  //   scrollYProgress,
  //   [0.07, 0.12, 0.38, 0.4],
  //   [0, 1, 1, 0],
  //   {
  //     ease: easeInOutQuad,
  //   }
  // );
  // const headingPart2Opacity = useTransform(
  //   scrollYProgress,
  //   [0.16, 0.2, 0.38, 0.4],
  //   [0, 1, 1, 0],
  //   {
  //     ease: easeInOutQuad,
  //   }
  // );
  // const headingPart3Opacity = useTransform(
  //   scrollYProgress,
  //   [0.22, 0.27, 0.38, 0.4],
  //   [0, 1, 1, 0],
  //   {
  //     ease: easeInOutQuad,
  //   }
  // );
  // const unmatchedOpacity = useTransform(
  //   scrollYProgress,
  //   [0.27, 0.3, 0.38, 0.4],
  //   [0, 1, 1, 0],
  //   {
  //     ease: easeInOutQuad,
  //   }
  // );
  // const lineScale = useTransform(scrollYProgress, [0.28, 0.3], [0, 1], {
  //   ease: easeInOutQuad,
  // });

  // // section 2
  // const usingReactOpacity = useTransform(
  //   scrollYProgress,
  //   [0.4, 0.48, 0.55, 0.6],
  //   [0, 1, 1, 0],
  //   {
  //     ease: easeInOutQuad,
  //   }
  // );

  // // section 3
  // const whyOpacity = useTransform(
  //   scrollYProgress,
  //   [0.65, 0.7, 0.8, 0.85],
  //   [0, 1, 1, 0],
  //   {
  //     ease: easeInOutQuad,
  //   }
  // );

  return (
    <motion.div
      className="relative"
      style={{ "--text-scale": animations.root_scale } as any}
    >
      <figure>
        <motion.span
          style={{
            scale: animations.root_circle2Scale,
            opacity: animations.root_circle2Opacity,
          }}
          className="pointer-events-none fixed inset-0 block bg-cover opacity-50 [background:url(/noise.svg)_cover,radial-gradient(circle_at_center,rgba(255,0,0,.3)_50%,rgba(0,255,0,.3)_80%)] [mask-image:radial-gradient(circle_at_50%,black_50%,transparent_80%)]"
        />
        <motion.span
          style={{
            scale: animations.root_circleScale,
            opacity: animations.root_circleOpacity,
          }}
          className="pointer-events-none fixed inset-0 block bg-cover opacity-50 [background:url(/course-media/framer-motion/noise.svg)_no-repeat_center] [mask-image:radial-gradient(circle_at_50%,black_50%,transparent_80%)]"
        />
      </figure>
      <div ref={ref}>
        <Container className="relative my-[30rem] w-[100ch] max-w-[90vw] text-center text-lg">
          <TextSection
            sectionSize="min-h-[400vh]"
            className="pointer-events-none"
            style={{
              position: animations.screen1_position,
            }}
          >
            <h2 className="mb-4 text-2xl md:text-4xl">
              <motion.span
                style={{ opacity: animations.screen1_heading1 }}
                className="block text-3xl opacity-0 md:text-[150px]"
              >
                Motion for React
              </motion.span>{" "}
              <motion.span
                style={{ opacity: animations.screen1_heading2 }}
                className="opacity-0"
              >
                is one of the best libraries
              </motion.span>{" "}
              <motion.span
                style={{ opacity: animations.screen1_heading3 }}
                className="opacity-0"
              >
                in the React ecosystem.
              </motion.span>
              <span className="block text-2xl md:text-3xl">
                <span className="relative inline-block">
                  <motion.span
                    style={{ opacity: animations.screen1_unmatched }}
                  >
                    Unmatched
                  </motion.span>
                  {currentChapter === "screen1" && (
                    <motion.span
                      style={{ scaleX: animations.screen1_lineScale }}
                      className="absolute block h-0 w-full border-[2px] border-white bg-transparent md:border-[6px]"
                    />
                  )}
                </span>{" "}
                <motion.span style={{ opacity: animations.screen1_unmatched }}>
                  in any other framework.
                </motion.span>
              </span>
            </h2>
          </TextSection>

          <TextSection
            sectionSize="h-[200vh]"
            className="pointer-events-none"
            style={{
              position: animations.screen2_position,
            }}
          >
            <motion.p
              style={{ opacity: animations.screen2_usingReactOpacity }}
              className="relative mb-4 border-8 border-white p-6 text-3xl !leading-[1] text-white md:p-12 md:text-[98px]"
            >
              <motion.span
                style={{
                  scale: animations.screen2_usingReactScale,
                }}
                className="block"
              >
                It's perhaps one of the biggest reasons I'm still using React
                today.
              </motion.span>
            </motion.p>
          </TextSection>

          <TextSection
            sectionSize="min-h-[50vh]"
            className="pointer-events-none"
            style={{
              position: animations.screen3_position,
            }}
          >
            <div className="flex items-center">
              <motion.p
                className="mt-18 text-[72px] md:text-[150px]"
                style={{
                  opacity: animations.screen3_whyOpacity,
                  x: animations.screen3_whyX,
                }}
              >
                <motion.span
                  style={{
                    x: animations.screen3_lineX,
                    opacity: animations.screen3_lineOpacity,
                  }}
                  layoutId="whiteblock"
                  className="absolute bottom-0 left-0 -z-10 h-2 w-full border-[6px] border-white bg-transparent md:h-3"
                />
                But why?
              </motion.p>
            </div>
          </TextSection>
        </Container>
      </div>
    </motion.div>
  );
};

export const FlexDirectionAnimation = () => {
  const [direction, setDirection] = useState<"row" | "row-reverse">("row");

  return (
    <div className="relative flex flex-col items-center justify-center border border-white-opaque bg-primary-gradient p-5 md:rounded-3xl">
      <div
        className={twMerge(
          "flex items-center justify-center gap-4 py-5",
          direction === "row" ? "flex-row" : "flex-row-reverse",
        )}
      >
        <motion.div layout className="rounded-2xl bg-white-opaque p-3">
          I'm left
        </motion.div>
        <motion.div layout>You sure about that? 🤔</motion.div>
      </div>
      <Button
        intent="white"
        onClick={() =>
          setDirection((dir) => (dir === "row" ? "row-reverse" : "row"))
        }
      >
        Toggle direction
      </Button>
    </div>
  );
};
