import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  id?: string;
  children: React.ReactNode;
};

export const Container = ({ id, className, children }: Props) => (
  <div
    id={id}
    className={twMerge("mx-auto w-full max-w-[134rem] px-4", className)}
  >
    {children}
  </div>
);
